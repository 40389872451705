@import '../../Assets/Styles/variables.scss';

.actionLink {
    color: $primaryColor;
    font-family: $secondaryFont;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;    
    text-decoration: underline;
}