@import '../Assets/Styles/variables.scss';

.expanderRow {
    @include expanderRow;
}

.credentialContainer{
    margin-left: 35px;
}
.credentialCol {    
    margin-left: -0.7em;
}
.ckShareCol{
    padding-left: 10px;
}
