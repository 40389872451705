@import '../Assets/Styles/variables.scss';

.contentDiv {
    padding-left: 15px;
    padding-top: 10px;
}

.refNumberPad {
    padding-left: 11px;
}

.NextSteps {
    padding-left: 25px;
}

.loadingImg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    margin-bottom: auto;
}
