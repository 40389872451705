$primaryColor: #013763;
$secondaryColor: #1f9055;
$primaryFont: 'Montserrat';
$secondaryFont: 'Source Sans Pro';
$disabledOpacity: 0.5;
$disabledColor: #858585;
$primaryBorder: 1px solid #C4C4C4;


@mixin expanderRow {
    border-bottom: $primaryBorder;
    font-family: $secondaryFont;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 16px;
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    color: $primaryColor;
}
body {
    font-family: $secondaryFont;
    color: $primaryColor;
}