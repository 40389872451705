@import '../Assets/Styles/variables.scss';

.container {
    margin-bottom: 20px;
}

.dashBoardListHeader {
    color: $secondaryColor;
    font-size: 24px;
    font-family: $primaryFont;
    font-weight: 600;
    line-height: 40px;
}

.button {
    position: absolute;
    right: 10px;
}

.text {
    color: $primaryColor;
    font-family: $secondaryFont;
    font-weight: 600;
    line-height: 24px;
}

.subtitle {
    font-size: 16px;
    font-weight: 600;
}

.subtext {
    font-size: 14px;
    font-weight: 400;
    font-style: italic;
}