.container {
    margin: 120px 20px 10px;
    min-height: 100px;
}

.doclist {
    position: absolute;
    bottom: 0;
    left: 20px;
}

.subtotal {
    position: absolute;
    bottom: 0;
    right: 10px;
    text-align: right;
}