@import '../../Assets/Styles/variables.scss';

.actionLink {
    color: $primaryColor;
    font-family: $secondaryFont;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    text-decoration: underline;
}
h1 {
    font-family: $primaryFont;
    color: $secondaryColor;    
    line-height: 24px;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
}
p, ul {    
    font-size: 16px;
    line-height: 24px;
    font-style: normal;
}