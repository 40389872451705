@import '../Assets/Styles/variables.scss';

.container {
    width: 100%;
    margin: 10px;
    //z-index: 10;
    text-align: center;
    position: relative;
}
.progressbar {
    overflow: hidden;
}
.progressbar li {
    list-style-type: none;   
    float: left;
    font-family: $secondaryFont;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    position: relative;
    text-align: center;
    color: $primaryColor;
    z-index: 1;
}
.progressbar li:before {
    border: 2px solid $secondaryColor;
    width: 35px;
    height: 35px;
    content: '';
    //content: url(../Assets/Images/progress-not-filled.svg);
    line-height: 30px;
    display: block;
    text-align: center;
    margin: 0 auto 10px auto;
    border-radius: 100%;
    background-color: white;
}

.progressbar li:after {
    background-color: $secondaryColor;
    width: 100%;
    height: 2px;
    content: '';
    position: absolute;
    top: 15px;
    left: -42%;
    z-index: -1;
}
.progressbar li.step2:after {   
    left: -48%;    
}

.progressbar li.step3:after {
    left: -44%;
}

.progressbar li:first-child:after {
    content: none;
}

.progressbar li.active {
    color: $primaryColor;
}

.progressbar li.active:before {
    background-color: $secondaryColor;
    vertical-align: middle;
    content: url(../Assets/Images/progress-bar-check-icon.svg);
    color: $primaryColor;
}

.progressbar li.current:before {
    background-color: $secondaryColor;
    vertical-align: middle;
    content: url(../Assets/Images/progress-filled.svg);
    color: $primaryColor;
}

.progressbar li.active + li:after {
    background-color: $secondaryColor;
}
