@import '../Assets/Styles/variables.scss';

.container {
    width: 100%;
    margin: 40px 0;
}

.header {
    // text-align: center;
    color: #5C5C5C;
    font-family: $secondaryFont;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 10px;
}

.noDataText {
    color: $primaryColor;
    font-family: $primaryFont;
    font-weight: 600;
}

.collapse {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
}

.expand {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
    margin-left: auto;
    content: "";
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3E%3Cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: 1.25rem;
    transition: transform .2s ease-in-out;
    transform: rotate(-90deg);
}

.expanderColumn {
    width: 50px;
}

.expandSeperator {
    margin-top: 10px;
    border-top: $primaryBorder;
}

.rowStyle {
    margin: 0 8px 10px 0;
    border-radius: 4px;
    padding-top: 15px;
    padding-bottom: 10px;
    border: $primaryBorder;
}

.dataItem {
    color: $primaryColor;
    font-family: $secondaryFont;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;
}