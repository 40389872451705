@import '../Assets/Styles/variables.scss';

.embedCover {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 99%;
}

.wrapper {
    position: relative;
    overflow: hidden;
}

.pdfWrapper {
    width: 100%;
    height:750px;
}