@import '../Assets/Styles/variables.scss';

.container {
    width: 30%;
    display: inline-block;
    margin-right: 20px;
    margin: 50px 20px 40px 0;
    min-width: 400px;
}
.descriptionContainer {
    border: 1px solid #dad8d6;
    padding: 25px 12px;
    border-radius: 4px;
}
.contentContainer {
    height: 200px;
    font-size: 16px;
    text-align: center;
}
