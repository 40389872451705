.contentView {
    height: 600px;
    width: 800px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 20px;
}

.content {
    margin: 10px;
}