@import './Assets/Styles/variables.scss';

html {
    overflow: auto;
    height: 100%;
    font-family: $primaryFont;
}

body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    color: $primaryColor;
}
a {
    color: $primaryColor;
}
a:link {
    text-decoration: none;
}
a:visited {
    text-decoration: none;
}
a:hover {
    text-decoration: none;
}
a:active {
    text-decoration: none;
}

.screen {
    width: 100%;
    height: 100%;
}

.header {
    width: 100%;
    height: 100px;
    background-color: white;
    border-bottom: 1px solid #C4C4C4;
}

.content {
    padding: 0px;
    /*overflow-y: auto;*/
    margin-left: 245px;
    height: auto;
}

.menu {
    background-color: #f9f8f8;
    overflow: hidden;
    height: 100%;
    width: 245px;
    float: left;
}

.activeMenuLink {
    color: $secondaryColor;
    font-weight: bold;
    border-left: 4px solid $secondaryColor;
    background-color: #EFEEED;    
}

.menuLink {
    color: #5C5C5C;
    font-family: $primaryFont;
    font-size: 18px;
    height: 52px;
    padding: 15px 0 0 20px;
    vertical-align: center;
}