@import '../Assets/Styles/variables.scss';

.header {
    margin-left: 0;
    margin-right: 20px;
    margin-bottom: 20px;
}

.title {
    font-family: $primaryFont;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 32px;
    color: $secondaryColor;
}

.subtitle, .subtext {
    font-family: $secondaryFont;
    color: $primaryColor;
    font-style: normal;
    font-weight: normal;    
    line-height: 24px;
}
.subtitle {
    font-size: 16px;
}
.subtext {    
    font-size: 14px;
    font-weight: 400;
    font-style: italic;    
}