@import '../Assets/Styles/variables.scss';

.welcomeUserName {
    color: $primaryColor;
    font-family: $primaryFont;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    text-align: right;
    font-weight: 600;
}

.grsNumber {
    color: $primaryColor;
    font-family: $primaryFont;
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    text-align: right;    
}