@import '../Assets/Styles/variables.scss';

.formLabel {
    /* white-space: nowrap; */
    font-family: Source Sans Pro;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    color: $primaryColor;
}

.requiredIcon {
    color: red;
}
form {
    padding: 0 15px;
}