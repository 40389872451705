@import '../../Assets/Styles/variables.scss';

.container {
    border: 1px solid $primaryColor;
    margin: 0 12px;
}

.title {
    background-color: $primaryColor;
    font-family: $primaryFont;
    font-weight: 600;
    color: white;
    font-size: 16px;
    line-height: 24px;
    padding: 10px;
    border: 1px solid $primaryColor;
}

.row {
    padding: 5px;
}

.label {
    font-family: $secondaryFont;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: $primaryColor;
}

.value {
    font-family: $secondaryFont;
    font-style: normal;
    font-size: 16px;
    line-height: 24px;
}