@import '../Assets/Styles/variables.scss';

.expanderRow {
    @include expanderRow;
}
.actions {
    margin-left: 24px;
    padding-left: 0;
}

