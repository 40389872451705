@import '../Assets/Styles/variables.scss';

.btnprimary {
    color: white;
    text-decoration: underline;
    border-color: $primaryColor;
    background-color: $primaryColor!important;
}

.btnsecondary {
    color: $primaryColor;
    border: 1px solid $primaryColor;
    text-decoration: underline;
}

.span {
    height: 38px;
    padding: 9px 35px;
    line-height: 1;
    display: inline-block;
    border-radius: 32px;
    border: 2px solid transparent;
    transition: all 0.1s;
}

.btndisabled {
    opacity: $disabledOpacity;
}

.btn {
    white-space: nowrap;
    height: 40px;
    font-family: $primaryFont;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;
    display: inline-block;
    padding: 0;
    background: #fff;    
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border-radius: 32px;
    transition: all 0.1s;
}