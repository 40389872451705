@import '../../Assets/Styles/variables.scss';

.priceLabel {
    color: $primaryColor;
    font-family: $primaryFont;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    padding-left: 11px;
}