@import '../Assets/Styles/variables.scss';

.title {
    color: $secondaryColor;
    font-size: 24px;
    font-family: $primaryFont;
    font-weight: bold;
}

.modalContent {
    background-color: white;
    width: 100%;
    border-radius: .3rem;
    border: 0px !important;
}

.modalBody {
    padding: 0;
    min-height: 300px;
}

.disabled {
    opacity: $disabledOpacity;
}

.footer {
    background-color: $primaryColor;
}

.nextBtn {
    position: absolute;
    right: 10px;
}

.footerBtn {
    color: #fff;
    font-size: 16px;
    font-family: $primaryFont;
    font-weight: bold;
    border: none;
    padding: 10px;
    margin: 10px;
    background-color: $primaryColor;
}