@import '../Assets/Styles/variables.scss';

.content {
    color: #013763;
    border: 1px solid #C4C4C4;
    background-color: white;
    box-sizing: border-box;
    border-radius: 4px;
    text-align: center;
    padding: 10px;
    font-size: 18px;
    line-height: 30px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    /* width: 100%;
    height: 200px; */
}